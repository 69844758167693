module.exports = [{
      plugin: require('../../../../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.12.4_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TechX Home","short_name":"TechX Home","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef740e9d00f7b5cd154336207ba16cff"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/.pnpm/gatsby@5.12.4_@swc+core@1.3.100_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
